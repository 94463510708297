import React from 'react';
import './style.scss';
import { MdOpenInNew } from 'react-icons/md';
function Home() {
    return (
        <div className='container depoimentos'>
            <div className="row">
                <div className="col-12">
                    <h2 className='titulo'>
                        Depoimentos
                    </h2>
                </div>
                <div className='col-12 col-md-4'>
                    <div class="card" data-aos="fade-right">
                        <a href="https://g.co/kgs/zEKckY" target="_blank">
                            <h3 class="card__title">
                                Luceli Luz <MdOpenInNew></MdOpenInNew>
                            </h3>
                        </a>
                        <p class="card__content">Produtos de qualidade, entrega rápida e atendimento diferenciado. Super indico.</p>
                        <div class="card__date">
                            Janeiro 27, 2018
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div class="card" data-aos="fade-right">
                        <a href="https://g.co/kgs/cXeqKd" target="_blank">
                            <h3 class="card__title">
                                Ozeas Alves <MdOpenInNew></MdOpenInNew>
                            </h3>
                        </a>
                        <p class="card__content">Exelente preços. Atendimento fenômenal..</p>
                        <div class="card__date">
                            Junho 23, 2021
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div class="card" data-aos="fade-right">
                        <a href="https://g.co/kgs/dpso4K" target="_blank">
                            <h3 class="card__title">
                                Yan Ueno <MdOpenInNew></MdOpenInNew>
                            </h3>
                        </a>
                        <p class="card__content">Empresa com atendimento ótimo e produtos de qualidade !</p>
                        <div class="card__date">
                            Junho 16, 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Home;