import React from 'react';
import './style.scss'
import confianca from "./img/confiarTESTEbranco.png"
import qualidade from "./img/qualidadeBranco.png"
import responsabilidade from "./img/reciclagemBranco.png"
function Valores() {
    return (
        <div className="container-fluid bglaranja">
            <div className="row valores">
                <div className="col-12">
                    <h2 className='titulo'>
                        Nossos Valores
                    </h2>
                </div>
                <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                    <img src={confianca} alt="" />
                    <div>
                        <h2 className='subt'>
                            Confiança
                        </h2>
                        <p className='texto'>
                            Na nossa empresa, a confiança é a base de todos os nossos relacionamentos comerciais. Buscamos estabelecer e manter a confiança dos nossos clientes, colaboradores e parceiros, priorizando a transparência, a honestidade e o respeito mútuo. Cumprimos nossas promessas, garantindo que nossos clientes possam confiar plenamente em nossa capacidade de atender às suas necessidades e expectativas.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                    <img src={qualidade} alt="" />
                    <div>
                        <h2 className='subt'>
                            Qualidade
                        </h2>
                        <p className='texto'>
                            A qualidade é um valor fundamental que permeia todas as etapas do nosso trabalho. Desde a seleção criteriosa de materiais e fornecedores até a execução dos processos e a entrega final, nos empenhamos para oferecer produtos e serviços de excelência. Nossa equipe altamente qualificada está comprometida em buscar a melhoria contínua, garantindo que cada produto e serviço que oferecemos atenda aos mais altos padrões de qualidade.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                    <img src={responsabilidade} alt="" />
                    <div>
                        <h2 className='subt'>
                            Responsabilidade
                        </h2>
                        <p className='texto'>
                            A responsabilidade é um princípio que orienta todas as nossas ações. Assumimos a responsabilidade não apenas pelos resultados dos nossos negócios, mas também pelo impacto que temos na sociedade e no meio ambiente. Agimos de maneira ética, sustentável e socialmente responsável, respeitando os direitos humanos, promovendo a diversidade e adotando práticas empresariais responsáveis. Estamos comprometidos em ser agentes de mudança positiva em todas as áreas em que atuamos.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Valores;