import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import './cssReset.css';
import './MainStyle.scss'
import Home from './Pages/Home/Home'
import Footer from './Components/Footer/Footer'
import Error from './Pages/Error/Error'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
  duration: 1000,
});
function App() {
  return (
    <Router>
      <Header />
      <div className="container-fluid">
        <div className="col-md-12 page">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="*" element={<Error />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
