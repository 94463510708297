import React from 'react';
import './style.scss'

function Hero() {
    return (
        <div className='container Hero'>
            <div className='txt'>
                <h2 className=''>Descartáveis / Embalagens / Higiene e Limpeza</h2>
                <h1 className='hero_text'>
                    A Maior <br /><i>Distribuidora</i> do <br /> Vale do Paraiba
                </h1>
                <a href="https://wa.me/5512997775571" className='ContactButton'>Faça uma cotação</a>
            </div>
            <div className='imgBg'>

            </div>
        </div>
    );
}

export default Hero;