import React from 'react';
import './style.scss';
function Stats() {
    return (
        <div className='container'>
            <div className='row stats'>
                <div className="col-12">
                    <h2 className='titulo'>
                        Nossos Números
                    </h2>
                </div>
                <div className="col-12 col-md-3" data-aos="fade-up">
                    <div className="cardd">
                        <div className='img caixas'></div>
                        <h2>3000</h2>
                        <h3>Produtos no Catálogo</h3>
                    </div>
                </div>
                <div className="col-12 col-md-3" data-aos="fade-up">
                    <div className="cardd">
                        <div className='img caminhao'></div>
                        <h2>340</h2>
                        <h3>Entregas por mês</h3>
                    </div>
                </div>
                <div className="col-12 col-md-3" data-aos="fade-up">
                    <div className="cardd">
                        <div className='img cliente'></div>
                        <h2>1000</h2>
                        <h3>Clientes Atendidos por Ano</h3>
                    </div>
                </div>
                <div className="col-12 col-md-3" data-aos="fade-up">
                    <div className="cardd">
                        <div className='img entregas'></div>
                        <h2>+10.000</h2>
                        <h3>Entregas Feitas</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;