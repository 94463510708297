import React from 'react';
import './style.scss'
import img from  '../../imagens/Limpeza.jpg';
function Error() {
  return (
    <div className="container error">
      <div className="row error">

        <div className='imagem col-12 col-md-6'>
          <img src={img} className='imagem' alt="imagem"/>
        </div>
        <div className='texto col-12 col-md-6'>
          <h3><span>404</span>  Pagina não encontrada.</h3>
        </div>
      </div>
    </div>
  );
}

export default Error;