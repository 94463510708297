import React from 'react';
import './style.scss'
import equipeLimpeza from "./img/equipeLimpeza.png"
import callcenter from "./img/callCenter.png"
import delivery from "./img/delivery.png"
function Why() {
    return (
        <>
            <section className='why'>
                <div className='container '>
                    <div className="row sobre" data-aos="fade-right">
                        <div className='col-12 col-sm-12 col-md-6 img'>
                            <img src={equipeLimpeza} alt="" />
                        </div>
                        <div className="col-12 col-sm-12  col-md-6 conteudo">
                            <h2 className='titulo'>
                                Sobre a Digiplas
                            </h2>
                            <p className='texto'>
                                A Distribuidora Digiplas é uma empresa estabelecida no mercado desde 1991 e especializada na distribuição de produtos de higiene, limpeza, embalagens e descartáveis. Localizada em São José dos Campos, contamos com uma infraestrutura sólida que nos permite planejar e organizar de forma eficiente toda a logística, visando atender empresas de todos os portes.
                            </p>
                            <p className='texto'>
                                Nossa prioridade é oferecer produtos e serviços de alta qualidade, sempre atentos às necessidades dos nossos clientes. Valorizamos um atendimento personalizado e dedicado, buscando superar suas expectativas. Na Digiplas, você pode confiar em soluções confiáveis e eficientes para suas demandas. Estamos prontos para atender você e proporcionar uma experiência excepcional.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='container '>
                    <div className="row sobre direita" data-aos="fade-right">
                        <div className="col-12 col-sm-12 col-md-6 conteudo">
                            <h2 className='titulo'>
                                Televendas
                            </h2>
                            <p className='texto'>
                                Nossa equipe de televendas altamente experiente está pronta para fornecer o melhor atendimento e suporte, garantindo que suas necessidades sejam supridas de forma eficiente. Valorizamos cada cliente e nos esforçamos para oferecer orientação personalizada, conhecimento aprofundado dos produtos e um processo de compra conveniente. Conte conosco para uma experiência de televendas excepcional e orientada para o cliente.
                            </p>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 img'>
                            <img src={callcenter} alt="" />
                        </div>
                    </div>
                </div>
                <div className='container '>
                    <div className="row sobre" data-aos="fade-right">
                        <div className='col-12 col-sm-12 col-md-6 img'>
                            <img src={delivery} alt="" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 conteudo">
                            <h2 className='titulo'>
                                Entrega
                            </h2>
                            <p className='texto'>
                                Nossa empresa possui uma frota de veículos especialmente equipada e pronta para entregar seus produtos com rapidez e eficiência, diretamente para você e sua empresa. Valorizamos a agilidade e entendemos a importância de receber seus pedidos o mais rápido possível. Confie em nós para fornecer um serviço de entrega confiável e pontual, garantindo que seus produtos cheguem em tempo hábil.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="container-fluid">
                    <div className="row valores">
                        <div className="col-12">
                            <h2 className='titulo'>
                                Nossos Valores
                            </h2>
                        </div>
                        <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                            <img src={confianca} alt="" />
                            <div>
                                <h2 className='subt'>
                                    Confiança
                                </h2>
                                <p className='texto'>
                                    Na nossa empresa, a confiança é a base de todos os nossos relacionamentos comerciais. Buscamos estabelecer e manter a confiança dos nossos clientes, colaboradores e parceiros, priorizando a transparência, a honestidade e o respeito mútuo. Cumprimos nossas promessas, garantindo que nossos clientes possam confiar plenamente em nossa capacidade de atender às suas necessidades e expectativas.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                            <img src={qualidade} alt="" />
                            <div>
                                <h2 className='subt'>
                                    Qualidade
                                </h2>
                                <p className='texto'>
                                    A qualidade é um valor fundamental que permeia todas as etapas do nosso trabalho. Desde a seleção criteriosa de materiais e fornecedores até a execução dos processos e a entrega final, nos empenhamos para oferecer produtos e serviços de excelência. Nossa equipe altamente qualificada está comprometida em buscar a melhoria contínua, garantindo que cada produto e serviço que oferecemos atenda aos mais altos padrões de qualidade.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 Card' data-aos="fade-right">
                            <img src={responsabilidade} alt="" />
                            <div>
                                <h2 className='subt'>
                                    Responsabilidade
                                </h2>
                                <p className='texto'>
                                    A responsabilidade é um princípio que orienta todas as nossas ações. Assumimos a responsabilidade não apenas pelos resultados dos nossos negócios, mas também pelo impacto que temos na sociedade e no meio ambiente. Agimos de maneira ética, sustentável e socialmente responsável, respeitando os direitos humanos, promovendo a diversidade e adotando práticas empresariais responsáveis. Estamos comprometidos em ser agentes de mudança positiva em todas as áreas em que atuamos.
                                </p>
                            </div>
                        </div>

                    </div>
                </div> */}
            </section>

        </>
    );
}

export default Why;