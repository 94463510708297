import React from 'react';
import './style.scss';
import Hero from "../../Components/Hero/Hero";
import Why from "../../Components/Why/why";
import Parceiros from '../../Components/Parceiros/Parceiros';
import Depoimentos from "../../Components/Depoimentos/Depoimentos"
import Contato from "../../Components/Contato/contato"
import Valores from "../../Components/Valores/index"
import Stats from "../../Components/Stats/Stats"
function Home() {
  return (
    <>
      <Hero/>
      <Why/>
      <Valores/>
      <Stats/>
      <Contato/>
      <Parceiros/>
      <Depoimentos/>
    </>
  );
}

export default Home;