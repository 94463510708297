import Carousel from 'react-bootstrap/Carousel';
import './style.scss'

import parceiro1 from './parceiros/parceiro1.png'
import parceiro2 from './parceiros/parceiro2.png'
import parceiro3 from './parceiros/parceiro3.png'
import parceiro4 from './parceiros/parceiro4.png'
import parceiro5 from './parceiros/parceiro5.png'
import parceiro6 from './parceiros/parceiro6.png'
import parceiro7 from './parceiros/parceiro7.png'
import parceiro8 from './parceiros/parceiro8.png'
// import parceiro9 from '../parceiros/parceiro9.png'
// import parceiro10 from '../parceiros/parceiro10.png'
// import parceiro11 from '../parceiros/parceiro11.png'
// import parceiro12 from '../parceiros/parceiro12.png'
// import parceiro13 from '../parceiros/parceiro13.png'

function Parceiros() {
    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className="col-12 titulo">
                        <h2 className='titulo'>
                            Marcas Parceiras
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container carrosel">
                <img src={parceiro1} alt="" />
                <img src={parceiro2} alt="" />
                <img src={parceiro3} alt="" />
                <img src={parceiro4} alt="" />
                <img src={parceiro5} alt="" />
                <img src={parceiro6} alt="" />
                <img src={parceiro7} alt="" />
                <img src={parceiro8} alt="" />
            </div>
        </>

    );
}

export default Parceiros;