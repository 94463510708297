import React from 'react';
import './style.scss';
import { FiPhoneCall } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiOutlineClockCircle, AiOutlineWhatsApp } from 'react-icons/ai';
function Contato() {
    return (
        <div className='container contato'>
            <div className='row'>
                <div className="col-12">
                    <h2 className='titulo'>
                        Entre em Contato !
                    </h2>
                </div>
                <div className='col-12 col-lg-6'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.6842588547975!2d-45.91578622381133!3d-23.254574050394798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc3552e761689b%3A0x10c3dd6a5892651e!2sDigiplas%20Distribuidora!5e0!3m2!1spt-BR!2sbr!4v1686858965438!5m2!1spt-BR!2sbr" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <FaMapMarkerAlt /> */}
                    <h2 className='txt d-inline'> Av. Feira de Santana, 340 - Jardim Vale do Sol, São José dos Campos </h2>
                </div>
                <div className='col-12 col-lg-6 conteudo row'>
                    <div className="col-12"><h2 className='subt'>Contato</h2></div>
                    <div className="col-12 col-md-6 contactCard" data-aos="fade-right">
                        <span><FiPhoneCall /></span>
                        <h2 className='texto'>Faça seu pedido !</h2>
                        <h2><a target='_blank' href="tel:(12) 3939-9901">(12) 3939-9901</a></h2>
                    </div>
                    <div className="col-12 col-md-6 contactCard" data-aos="fade-right">
                        <span><AiOutlineWhatsApp /></span>
                        <h2 className='texto'>Entre em contato pelo WhatsApp !</h2>
                        <h2><a target='_blank' href="https://wa.me/5512997775571">(12) 99777-5571 </a></h2>
                    </div>
                    <div className="col-12 col-md-6 contactCard" data-aos="fade-right">
                        <span><MdEmail /></span>
                        <h2 className='texto'>Tem alguma dúvida ?</h2>
                        <h2><a target='_blank' href="mailto:digiplas@digiplas.com.br">digiplas@digiplas.com.br</a></h2>
                    </div>
                    <div className="col-12 col-md-6 contactCard" data-aos="fade-right">
                        <span><MdEmail /></span>
                        <h2 className='texto'>Faça parte da nossa equipe</h2>
                        <h2><a target='_blank' href="mailto:curriculorecrutamento1@gmail.com">curriculorecrutamento1@gmail.com</a></h2>
                    </div>
                    <div className="col-12"><h2 className='subt'>Horário de funcionamento</h2></div>
                    <div className="col-12 col-md-6 mt-4 horario" data-aos="fade-right">
                        <span><AiOutlineClockCircle /></span>
                        <h2 className='texto'>Segunda - Quinta</h2>
                        <h2 className='negr'>07:00 à 12:00</h2>
                        <h2 className='negr'>13:00 à 17:00</h2>
                    </div>
                    <div className="col-12 col-md-6 mt-4 horario" data-aos="fade-right">
                        <span><AiOutlineClockCircle /></span>
                        <h2 className='texto'>Sexta feira</h2>
                        <h2 className='negr'>07:00 à 12:00</h2>
                        <h2 className='negr'>13:00 à 16:00</h2>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Contato;